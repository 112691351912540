module.exports.KEY_NUMBER = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
module.exports.KEY_NEED = [
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowUp",
  "ArrowRight",
  "ArrowDown",
  "Tab"
];
module.exports.CODE_SPECIAL = [
  "NumpadMultiply",
  "NumpadAdd",
  "NumpadSubtract",
  "NumpadDecimal",
  "NumpadDivide",
  "Semicolon",
  "Equal",
  "Comma",
  "Minus",
  "Period",
  "Slash",
  "Backquote",
  "BracketLeft",
  "Backslash",
  "BracketRight",
  "Quote",
];
