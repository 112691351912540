import React, { useState } from "react";
import { isMobile, isSafari } from "react-device-detect";
import LoadingIndicator from "../Util/LoadingIndicator";
const ImageBox = ({
  image,
  title,
  detail,
  hoverHandler,
  blurHandler,
  clickHandler,
  guide,
}) => {
  const [hover, setHover] = useState(false, () => {});
  const [loading, setLoading] = useState(true, () => {});
  return (
    <div
      onMouseEnter={() => {
        if (!isMobile) {
          hoverHandler();
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          blurHandler();
          setHover(false);
        }
      }}
      onClick={clickHandler}
      className={`Product-item-Cover ${!isSafari &&
        "Product-item-Cover_Non-safari"} ${
        hover && !isSafari ? "Product-item-Cover-Hover" : ""
      }`}
    >
      <div className={`Product-item ${!isSafari && "Product-item_Non-safari"}`}>
        <img
          src={image.imageUrl}
          style={{
            objectFit: `${image.type && image.type}`,
            opacity: `${loading ? 0 : 1}`
          }}
          alt={image.name}
          className="Item-image"
          onLoad={()=>{setLoading(false)}}
        />

        {loading && <LoadingIndicator className={"Item-image Item-loading"} />}
        {!isSafari && (
          <div className="Item-name">
            <h1 style={{ textTransform: "capitalize" }}>{title}</h1>
          </div>
        )}
        {!isSafari && (
          <div className="Item-detail">
            <div className="Item-detail-Mask"></div>
            {detail.map((text, index) => (
              <p className="Item-detail-Paragraph" key={index}>
                {text}
              </p>
            ))}
          </div>
        )}
        <div className={`Item-guide ${!isSafari && "Item-guide_Non-safari"}`}>
          {guide}
        </div>
      </div>
    </div>
  );
};

export default ImageBox;
