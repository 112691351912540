import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Content from "../../Content/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import LanguageContext from "../../Context/Language.Context";
import ProductContext from "../../Context/Product.Context";

export default function MenuBtnSection() {
  const navigate = useNavigate();
  const location = useLocation();
  return () => {
    const { currentLanguage } = useContext(LanguageContext);
    const { currentProduct, setCurrentProduct } = useContext(ProductContext);
    if(currentLanguage === null) return
    const newContent = Content[currentLanguage].menu;
    return newContent.map((item, index) => {
      return (
        <div key={index} className="Menu-item-Container">
          <div
            className={`Menu-item ${
              location.pathname === item.path && !item.children
                ? "Menu-active"
                : ""
            }`}
            onClick={() => {
              if (!item.children) navigate(item.path);
            }}
          >
            <div>{item.name}</div>
            {/* {item.children && (
              <FontAwesomeIcon
                icon={faPlus}
                style={{transform: `rotate(${true ? 405 : 0}deg)`, transition: `all .4s ease-out .1s`}}
              />
            )} */}
            {item.children && (
              <ul className="Menu-sub-item-Container">
                {item.children.map((child, cIndex) => (
                  <li
                    key={cIndex}
                    className={`Menu-sub-item ${currentProduct === cIndex && location.pathname === "/products" &&
                      "Menu-sub-active"}`}
                    onClick={() => {
                      navigate(item.path);
                      setCurrentProduct(cIndex);
                    }}
                  >
                    {child}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      );
    });
  };
}
