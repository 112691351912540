import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Button({ icon, className, handler }) {
  return (
    <div
      onClick={handler}
      className={`Product-button ${className && className}`}
    >
        <FontAwesomeIcon icon={icon} />
    </div>
  );
}
