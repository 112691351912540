import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconBox = ({ link, tittle, icon }) => {
  return (
    <a className="Link-Button" href={link} title={tittle}>
      <FontAwesomeIcon icon={icon} />
    </a>
  );
};

export default IconBox;
