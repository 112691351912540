import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes, useNavigate } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import axios from "axios";
import "./App.css";
import LanguageContext from "./Context/Language.Context";
import ProductContext from "./Context/Product.Context";
import HomeContext from "./Context/Home.Context";
import ScrollingContext from "./Context/Scrolling.Context";
import ScrollEndContext from "./Context/ScrollEnd.Context";
import PromContext from "./Context/Product.Context";
import Header from "./Components/Header/index.Header";
import Home from "./Components/Home/index.Home";
import Products from "./Components/Products/index.Products";
import Contact from "./Components/Contact/index.Contact";
import Footer from "./Components/Footer/index.Footer";
const EXPIRED_BY = 1675184400000

function App() {
  if(!isBrowser && Date.now() >= EXPIRED_BY) return <></>
  const [requests, setRequests] = useState([], () => {});
  const [scrollEnd, setScrollEnd] = useState(false, () => {});
  const [currentLanguage, setCurrentLanguage] = useState(null, () => {});
  const [currentProduct, setCurrentProduct] = useState(null, () => {});
  const [currentSection, setCurrentSection] = useState(null, () => {});
  const [isScrolling, setIsScrolling] = useState(false, () => {});
  
 
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("path", location.pathname);
  }, [location]);
  useEffect(() => {
    if (currentLanguage !== null)
      localStorage.setItem("language", JSON.stringify(currentLanguage));
  }, [currentLanguage]);
  useEffect(() => {
    navigate(localStorage.getItem("path") ? localStorage.getItem("path") : "/");
    const localLanguage = JSON.parse(localStorage.getItem("language"));
    if (localLanguage !== null) {
      setCurrentLanguage(localLanguage);
    } else {
      const language =
        window.navigator.language || window.navigator.userLanguage;
      const newLang = language.slice(0, 2) === "vi" ? 0 : 1;
      setCurrentLanguage(newLang);
    }
    window.onresize = () => {
      window.location.reload();
    };
    // home.map((path, index) => {
    //   axios
    //     .get(`${path}`, {
    //       responseType: "arraybuffer",
    //     })
    //     .then((response) => {
    //       let base64;
    //       if (index) {
    //         base64 = window.btoa(
    //           new Uint8Array(response.data).reduce(
    //             (data, byte) => data + String.fromCharCode(byte),
    //             ""
    //           )
    //         );
    //       } else {
    //         let binary = "";
    //         const bytes = new Uint8Array(response.data);
    //         const len = bytes.byteLength;
    //         for (var i = 0; i < len; i++) {
    //           binary += String.fromCharCode(bytes[i]);
    //         }
    //         base64 = window.btoa(binary);
    //       }
    //       const newHomeSources = homeSources;
    //       newHomeSources[index] = "data:;base64," + base64;
    //       setHomeSources([...newHomeSources]);
    //       const newLoading = loading;
    //       newLoading[index] = false;
    //       setLoading([...newLoading]);
    //     });
    // });
    // INITIAL_PRODUCT_IMAGE.forEach((section, index) => {
    //   section.forEach((src, sIndex) => {
    //     axios
    //       .get(`${src.imageUrl}`, {
    //         responseType: "arraybuffer",
    //       })
    //       .then((response) => {
    //         const base64 = btoa(
    //           new Uint8Array(response.data).reduce(
    //             (data, byte) => data + String.fromCharCode(byte),
    //             ""
    //           )
    //         );
    //         const newProductSources = productSources;
    //         newProductSources[index][sIndex] = {
    //           ...newProductSources[index][sIndex],
    //           imageSrc: "data:;base64," + base64,
    //         };
    //         setProductSources([...newProductSources]);
    //       });
    //   });
    //   axios
    //     .get(`/Images-videos/contact.gif`, {
    //       responseType: "arraybuffer",
    //     })
    //     .then((response) => {
    //       const base64 = btoa(
    //         new Uint8Array(response.data).reduce(
    //           (data, byte) => data + String.fromCharCode(byte),
    //           ""
    //         )
    //       );
    //       setContactSrc("data:;base64," + base64);
    //     });
    // });
  }, []);
  return (
    <div className="App">
      <PromContext.Provider value={{ requests, setRequests }}>
        <ScrollEndContext.Provider value={{ scrollEnd, setScrollEnd }}>
          <LanguageContext.Provider
            value={{ currentLanguage, setCurrentLanguage }}
          >
            <HomeContext.Provider value={{ currentSection, setCurrentSection }}>
              <ProductContext.Provider
                value={{ currentProduct, setCurrentProduct }}
              >
                <ScrollingContext.Provider
                  value={{ isScrolling, setIsScrolling }}
                >
                    <Header />
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={<Home />}
                      />
                      <Route
                        path="/products"
                        element={<Products />}
                      />
                      {/* <Route
                        path="/contact"
                        element={<Contact />}
                      /> */}
                    </Routes>
                    <Footer />
                </ScrollingContext.Provider>
              </ProductContext.Provider>
            </HomeContext.Provider>
          </LanguageContext.Provider>
        </ScrollEndContext.Provider>
      </PromContext.Provider>
    </div>
  );
}

export default App;
