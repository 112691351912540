import React from "react";

const DotSlider = ({ items, currentItem, toggleHandler, isEndAnimation }) => {
  return (
    <div className="Product-dot-slider">
      {items.map((item, index) => (
        <div
          className={`Dot-slider ${
            currentItem === index ? "Dot-slider-Active" : ""
          } ${
            isEndAnimation
              ? ""
              : currentItem === index
              ? "Disable"
              : "Disable-display"
          }`}
          key={index}
          onClick={() => {
            toggleHandler(index);
          }}
        ></div>
      ))}
    </div>
  );
};

export default DotSlider;
