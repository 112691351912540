import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import Content from "../../Content/Content";
import LanguageContext from "../../Context/Language.Context";
const EXPIRED_BY = 1675184400000
const LoadingIndicator = ({ className }) => {
  if(Date.now() >= EXPIRED_BY) return <></>
  const POSTFIX = "...";
  const [content, setContent] = useState("", () => {});
  const { currentLanguage } = useContext(LanguageContext);
  useEffect(() => {
    if (!Content[currentLanguage]) {
      setTimeout(() => {
        if (POSTFIX === content) {
          setContent("");
          return;
        }
        setContent(POSTFIX.slice(0, content.length + 1));
      }, 150);
    } else {
      const message = Content[currentLanguage].loading;
      setTimeout(() => {
        if (message === content) {
          setContent("");
          return;
        }
        setContent(message.slice(0, content.length + 1));
      }, 150);
    }
  }, [content]);
  return (
    <div className={className}>
      <FontAwesomeIcon icon={faGear} spin />
      <h2>{content}</h2>
    </div>
  );
};

export default LoadingIndicator;
