import React from "react";

const LogoBox = ({ link, title, logo, style }) => {
  return (
    <a href={link} target="_blank" title={title}>
      <img style={style} className="Info-button" src={logo} />
    </a>
  );
};

export default LogoBox;
