import React, { useContext } from "react";
import "./index.Header.css";
import {
  faBars,
  faGlobe,
  faSatellite,
} from "@fortawesome/free-solid-svg-icons";
import HeaderButton from "./HeaderButton";
import LangBtnSection from "./LangBtnSection";
import MenuBtnSection from "./MenuBtnSection";
import { useLocation } from "react-router-dom";
import ScrollingContext from "../../Context/Scrolling.Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Header() {
  const location = useLocation();
  const { isScrolling } = useContext(ScrollingContext);
  // "linear-gradient(to top, rgba(255, 255, 255, 0), #47b5ffa2)"
  return (
    <header
      className={`App-header ${isScrolling ? "App-header_Blur" : ""}${
        location.pathname == "/contact" ? "App-header_In-contact" : ""
      }`}
    >
      <div className="Header-Blur-layout-Container">
        <div className="Header-Blur-layout"></div>
        <div className="Header-Rainbow"></div>
      </div>
      <HeaderButton
        className="App-menu Header-button"
        icon={faBars}
        renderChildren={MenuBtnSection()}
        useHover={true}
      />

      {/* <img src={"/logo.png"} onTransitionEnd={()=>{setLogoSpin(false)}} className="Header-logo" style={{transform: `rotateY(${logoSpin ? 450 : 0}deg) rotateX(${logoSpin ? 720 : 0}deg)`, transition: `${logoSpin ? "transform .6s ease-in" : ""}`}} alt="logo" /> */}
      <HeaderButton
        className="App-languages Header-button"
        icon={faGlobe}
        renderChildren={LangBtnSection()}
        useHover={false}
      />
    </header>
  );
}
