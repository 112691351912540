const Content = [
  {
    //// vietnamese
    menu: [
      { name: "Giới thiệu công ty", path: "/" },
      {
        name: "Sản phẩm",
        path: "/products",
        children: ["Máy chủ", "Camera an ninh", "Thiết bị truy cập"],
      },
      // { name: "Liên hệ", path: "/contact" },
    ],
    home: [
      {
        title: "Giới thiệu doanh nghiệp",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "Công ty trách nhiệm hữu hạn một thành viên Hồng Hoa, còn được biết đến với cái tên công ty Công nghệ Hồng Hoa (Hong Hoa Tech) - là doanh nghiệp tiên phong sáng tạo và đổi mới trong lĩnh vực tư vấn, cung cấp và thi công các thiết bị Phần cứng, Hạ tầng mạng. Công ty được thành lập vào ngày 19 tháng 3 năm 2019 và có trụ sở tại ",
            },
            {
              type: "text",
              content: "phường Trảng Dài, thành phố Biên Hòa, tỉnh Đồng Nai",
              url:
                "https://www.google.com/maps/place/Khu+Ph%E1%BB%91+4,+Thi%E1%BB%87n+T%C3%A2n,+Th%C3%A0nh+ph%E1%BB%91+Bi%C3%AAn+H%C3%B2a,+%C4%90%E1%BB%93ng+Nai/@11.0019421,106.8760212,15z/data=!3m1!4b1!4m5!3m4!1s0x3174dd061067aef1:0xe58e3bec090770c!8m2!3d11.0019423!4d106.8760212",
            },
            { type: "text", content: ", mã số thuế doanh nghiệp là " },
            {
              type: "text",
              content: "3601573554",
              url:
                "https://masothue.com/3601573554-cong-ty-tnhh-mot-thanh-vien-hong-hoa",
            },
            {
              type: "text",
              content:
                ". Được xem là một trong những công ty khởi nghiệp đầy triển vọng trong khu vực Đông Nam Bộ, với hệ thống kỹ thuật, công nghệ và đội ngũ kỹ thuật viên của chính mình. Công ty đặt mục tiêu trở thành nhà cung cấp giải pháp phần cứng, hạ tầng mạng với dịch vụ chuyên nghiệp, sản phẩm công nghệ sáng tạo và chất lượng cao nhằm đáp ứng đầy đủ các nhu cầu của các cộng đồng, khách hàng và đối tác địa phương cũng như toàn quốc. Nhằm thúc đẩy quá trình phát triển và mở rộng việc kinh doanh, doanh nghiệp chúng tôi sẵn sàng hướng tới sự trung thực và chuyên nghiệp.",
            },
          ],
        ],
      },
      {
        title: "Sản phẩm và Dịch vụ",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "Công nghệ Hồng Hoa cung cấp một loạt các dịch vụ chuyên sâu về phát triển phần mềm và thiết kế phần cứng. Một doanh nghiệp, tổ chức muốn phát triển ổn định, hiệu quả và an toàn thì phải có một cơ sở hạ tầng công nghệ vững chắc và hệ thống thông tin ổn định làm nền móng. Đặc biệt cần phải chú trọng đến việc xây dựng và thiết lập hệ thống các thiết bị phần cứng, máy tính, máy in, hạ tầng mạng,... Nắm bắt được mấu chốt vấn đề, công ty chúng tôi tư vấn, cung cấp những giải pháp phù hợp với từng mô hình của doanh nghiệp, tổ chức. Chúng tôi tự tin vào khả năng thiết kế giải pháp, cung cấp và thi công của mình có thể giải quyết được vấn đề của doanh nghiệp, tổ chức. ",
            },
          ],
          [
            {
              type: "text",
              content:
                "Bên cạnh đó, Công nghệ Hồng Hoa còn cung cấp các giải pháp khác nhau cho từng vấn đề cụ thể của khách hàng là doanh nghiệp nhỏ hay tập đoàn lớn bao gồm giải pháp kiến trúc máy tính, phác thảo, thiết bị nhúng , mạng lưu trữ và trung tâm dữ liệu. Sản phẩm của công ty bao gồm ",
            },
            {
              type: "internalLink",
              content: "hệ thống máy chủ",
              path: "/products",
              id: 0,
            },
            {
              type: "text",
              content:
                " có thể phát triển cho các ứng dụng kinh doanh cũng như các ứng dụng doanh nghiệp phục vụ cho nhu cầu làm việc từ xa hoặc tại chỗ, hiện tại khả thi dưới hai hình thức lưu trữ đám mây (cloud) và lắp đặt tại chỗ (on-premise). Ngoài ra, chúng tôi còn cung cấp ",
            },
            {
              type: "internalLink",
              content: "hệ thống giám sát",
              path: "/products",
              id: 1,
            },
            { type: "text", content: " và " },
            {
              type: "internalLink",
              content: "thiết bị an ninh",
              path: "/products",
              id: 2,
            },
            {
              type: "text",
              content:
                " nhằm tối ưu việc mở rộng quy mô cho khách hàng từ máy tính bàn, máy tính xách tay cho đến siêu máy chủ tối tân.",
            },
          ],
        ],
      },
      {
        title: "Đội ngũ và Kinh nghiệm",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "Công ty Công nghệ Hồng Hoa là một trong những công ty công nghệ mới và phát triển nhất tại Việt Nam với những dữ kiện không thể phủ nhận. Tuy chỉ mới thành lập từ năm 2019 nhưng với kinh nghiệm hỗ trợ nhiều doanh nghiệp từ nhỏ đến lớn các giải pháp quản lý về phần cứng, phần mềm và hạ tầng mạng, chúng tôi hoàn toàn tự tin với khả năng của mình. Để giúp các doanh nghiệp phát triển nhanh chóng, chúng tôi cung cấp một loạt các dịch vụ và sản phẩm được điều chỉnh để đáp ứng một cách phù hợp với nhu cầu của doanh nghiệp và đối tác. Hơn thế nữa, chúng tôi còn cung cấp hỗ trợ phần mềm và dịch vụ quản lý để đáp ứng các nhu cầu cụ thể cho tệp khách hàng của doanh nghiệp. Chúng tôi có đội ngũ kỹ sư, chuyên gia với nhiều năm kinh nghiệm trong ngành công nghệ và đội ngũ nhân viên có tay nghề cao trong việc thi công và lắp đặt thiết bị, có thể giải quyết mọi thách thức kỹ thuật, cung cấp các giải pháp kỹ thuật chuyên nghiệp và chất lượng. Công nghệ Hồng Hoa đã làm việc trên nhiều sản phẩm và dự án khác nhau bao gồm các hệ thống quy mô lớn cho các khách sạn và ngân hàng tại Việt Nam. Mục tiêu của chúng tôi là tạo ra một công ty công nghệ vươn tầm quốc tế, nơi đội ngũ tài năng của chúng tôi sẽ là người cung cấp các giải pháp phù hợp, dễ dàng áp dụng và xử lý hiệu quả nhu cầu kinh doanh của các doanh nghiệp, tổ chức.",
            },
          ],
        ],
      },
      {
        title: "Khu vực và Chi phí",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "Các sản phẩm và dịch vụ của công ty được thiết kế để linh hoạt và tiết kiệm chi phí mà không ảnh hưởng đến chất lượng và hiệu quả. Với công nghệ và dịch vụ vượt trội của mình, Công nghệ Hồng Hoa sẵn sàng trở thành công ty công nghệ hàng đầu trong lĩnh vực phục vụ cho công nghiệp tại Bình Dương và Đồng Nai.",
            },
          ],
          [
            {
              type: "text",
              content:
                "Công nghệ Hồng Hoa là một công ty công nghệ chất lượng và đầy sáng tạo, cung cấp các giải pháp cho nhiều vấn đề khác nhau. Chúng tôi tập trung vào việc cung cấp các sản phẩm chất lượng để đáp ứng nhu cầu thay đổi của thị trường. Cùng với đó, chúng tôi cam kết mang lại sự hài lòng cho khách hàng thông qua chất lượng dịch vụ trước và sau thi công. Bởi vì nếu trong quá trình sử dụng, hệ thống bị gián đoạn hoặc tê liệt sẽ làm ảnh hưởng hiệu suất làm việc, gây thiệt hại về mặt tài chính cho doanh nghiệp và tổ chức. Từ đó mà chúng tôi luôn chú trọng với dịch vụ hỗ trợ bảo trì cho khách hàng. Đặc biệt, Công nghệ Hồng Hoa còn có một đội ngũ bảo trì tuyệt vời, luôn sẵn sàng có mặt để có thể giữ cho hệ thống và thiết bị công nghệ của khách hàng luôn hoạt động. Khách hàng có thể tin tưởng các sản phẩm và dịch vụ của Công nghệ Hồng Hoa khi lựa chọn đơn vị cung cấp công nghệ với chất lượng tốt nhất cùng giá cả có sức cạnh tranh lớn trên thị trường.",
            },
          ],
        ],
      },
      {
        title: "Tầm nhìn và Mục tiêu",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "Doanh nghiệp Công nghệ Hồng Hoa cam kết cung cấp các dịch vụ thiết kế phần cứng và phát triển phần mềm theo yêu cầu với chất lượng và độ hiệu quả cao. Mục tiêu của chúng tôi là cung cấp cho khách hàng và đối tác những sản phẩm chất lượng cao nhất, trong khi vẫn phù hợp với ngân sách tài chính của khách hàng. Tất cả các vấn đề phát sinh đều được chúng tôi xử lý và hỗ trợ tận nơi với mục tiêu làm hài lòng khách hàng. Chúng tôi sẽ không bao giờ thỏa mãn nếu khách hàng còn chưa hài lòng.",
            },
          ],
          [
            {
              type: "text",
              content:
                "Công nghệ đang thay đổi cách thức chúng ta hoạt động từ kinh doanh, di chuyển và sinh hoạt hàng ngày, giúp cuộc sống của mọi người trở nên dễ dàng hơn, an toàn hơn và thuận tiện hơn. Tại Công nghệ Hồng Hoa, chúng tôi muốn trở thành một phần của sự thay đổi đó. Chúng tôi đổi mới để cung cấp các giải pháp công nghệ cho khách hàng nhằm giúp cho khách hàng có được sản phẩm, dịch vụ tốt nhất cùng với chi phí thấp nhất. Hỗ trợ các doanh nghiệp, tổ chức áp dụng công nghệ thông tin vào công tác quản lý, vận hành, giám sát… Chúng tôi cam kết cung cấp cho khách hàng và đối tác những sản phẩm và hỗ trợ chất lượng cao để doanh nghiệp có thể cải thiện hoạt động kinh doanh của mình và có được sự thuận tiện trong vấn đề quản lý. Chúng tôi tin rằng bằng cách thực hiện những thay đổi này ngay bây giờ, chúng tôi có thể xây dựng một tương lai tốt đẹp hơn cho tất cả mọi người. Liên hệ với chúng tôi ngay hôm nay để tìm hiểu thêm và xem cách chúng tôi đáp ứng đầy đủ các giải pháp công nghệ phù hợp với nhu cầu kinh doanh của doanh nghiệp. Chân thành cảm ơn các khách hàng đã coi chúng tôi là đối tác công nghệ của mình!",
            },
          ],
        ],
      },
    ],
    products: [
      [
        {
          title: "Phòng máy chủ",
          detail: [
            "Phòng Máy Chủ hay Trung Tâm Dữ Liệu là những phòng có đầy đủ tính năng dành riêng cho việc hoạt động liên tục của các máy chủ máy tính. Thông thường, một Phòng Máy Chủ sẽ chứa các hệ thống máy chủ có thể điều khiển từ xa được bằng cách sử dụng KVM switch hoặc phần mềm quản trị từ xa như Secure Shell, VNC thông qua máy tính để bàn.",
            "Ngày nay, các Phòng Máy Chủ cung cấp nhiều tác vụ khác nhau như làm nơi lưu trữ dữ liệu từ các trang web, đầu thư điện tử và các ứng dụng web khác. Ngoài ra, Phòng Máy Chủ quản lý việc tối ưu hóa phân phối ứng dụng (AOD), băng thông internet, cũng như đảm bảo khả năng mở rộng và tính khả thi cho các tính năng trên.",
          ],
        },
        {
          title: "Cáp ethernet",
          detail: [
            "Cáp Ethernet là một dạng cáp mạng liên kết các thiết bị với nhau trong mạng cục bộ như máy tính, bộ định tuyến hoặc modem. Kết nối Ethernet có những hạn chế về khoảng cách, truyền tải tín hiệu và độ bền. Tuy nhiên, chúng có hiệu quả đáng kể trong việc kết nối internet về tốc độ, sự ổn định và đáng tin cậy hơn so với kết nối không dây.",
            "Cáp Ethernet là một công nghệ có thể cho phép hai thiết bị giao tiếp với nhau thông qua kết nối internet, cấp quyền truy cập vào mạng cục bộ (LAN). Đây là giải pháp phù hợp cho việc truy cập internet một cách riêng tư đối với gia đình và doanh nghiệp.",
          ],
        },
        {
          title: "Sợi quang học",
          detail: [
            "Sợi Quang Học hay còn gọi là Cáp Quang là một công nghệ truyền thông tin dưới dạng xung ánh sáng trong một khoảng cách rất xa bằng sợi thủy tinh hoặc các sợi nhựa trong suốt với kích thước rất nhỏ. Trên thị trường có rất nhiều loại Mạng Cáp Quang thông dụng khác nhau, nhưng tất cả đều được kết nối internet trực tiếp từ nhà cung cấp dịch vụ internet (IPS). ",
            "Cáp quang đến cơ sở (FTTP) là hình thức Mạng Cáp Quang nhanh nhất bởi vì nó được kết nối 100% bởi sợi cáp quang được lắp đặt từ nhà cung cấp dịch vụ internet đến các thiết bị đầu cuối kết nối trực tiếp tại các khu dân cư, khu chung cư và doanh nghiệp.",
          ],
        },
        {
          title: "Bộ định tuyến",
          detail: [
            `Bộ Định Tuyến (thường được gọi là Modem) là thiết bị mạng định tuyến dùng để chuyển các gói dữ liệu giữa mạng máy tính đến các thiết bị đầu cuối. Nói một cách dễ hiểu, bộ định tuyến là một thiết bị để chia sẻ internet tới nhiều các thiết bị khác trong cùng lớp mạng. Các gói dữ liệu, chẳng hạn như các trang web hoặc thư điện tử,... sẽ được gửi từ bộ định tuyến này sang bộ định tuyến tiếp theo thông qua các mạng nhỏ được kết nối với nhau bằng internet để xây dựng một mạng liên kết cho đến khi gói dữ liệu đến đích.`,
          ],
        },
        {
          title: "Máy chủ rack",
          detail: [
            "Máy Chủ Rack là Máy Chủ có gắn giá đỡ, phổ biến ở các trung tâm dữ liệu và doanh nghiệp nhỏ với các phòng máy chủ chuyên dụng.",
            "Rack Server có kích thước cơ sở lớn hơn so với các dòng máy tính hoặc máy chủ khác. Do đó, tùy thuộc vào thiết kế của máy chủ, nó có thể được gắn vào giá đỡ bằng vít và ray chuyên dụng. Rack Server là giải pháp thay thế hiệu quả nhất về chi phí cho doanh nghiệp chỉ cần một số lượng nhỏ các máy chủ với chi phí đầu tư ban đầu thấp.",
          ],
        },
        {
          title: "Máy chủ lưu trữ",
          detail: [
            "Có thể được sử dụng để lưu trữ, bảo mật, quản lý và truy cập vào các tệp dữ liệu. Máy Chủ Lưu Trữ cho phép người dùng truy cập khối lượng dữ liệu nhỏ đến lớn thông qua mạng chia sẻ hoặc internet. Máy Chủ Lưu Trữ thường không mạnh mẽ bằng một máy chủ chuẩn mực thông thường bởi thiếu hụt khả năng tạo ra nhiều không gian lưu trữ, giao diện truy cập lưu trữ, các tiện ích quản lý và truy xuất dữ liệu chuyên dụng.",
            "Máy Chủ Lưu Trữ không xử lý các hoạt động tính toán hoặc thực thi các ứng dụng khách. Máy chủ này được xây dựng dựa trên mô hình lưu trữ dữ liệu máy khách-máy chủ, trong đó máy khách hoặc máy trạm sẽ truy cập vào bộ nhớ.",
          ],
        },
        {
          title: "Máy chủ GPU",
          detail: [
            "GPU là tên viết tắt của Graphics Processing Unit, là bộ xử lý liên quan đến đồ họa của bộ xử lý trung tâm CPU. Do đó, Máy Chủ GPU sẽ có thể thực hiện các công việc nặng hoặc xử lý dữ liệu lớn với sự hỗ trợ của GPU.",
            "Trung bình, Máy Chủ GPU có tốc độ xử lý cao hơn và hình ảnh rõ ràng hơn so với các loại máy chủ khác. Máy Chủ GPU là một giải pháp thay thế tuyệt vời cho các doanh nghiệp có nhu cầu tính toán lớn hoặc xử lý lượng dữ liệu khổng lồ. Máy Chủ GPU có nhiều lợi ích khác nhau hơn so với các loại máy chủ khác.",
          ],
        },
        {
          title: "Máy chủ phiến",
          detail: [
            "Máy Chủ Phiến hay còn gọi là Máy Chủ Mật Độ Cao. Nó là một thiết bị nhỏ có chứa máy tính và được sử dụng để quản lý và phân phối dữ liệu trong mạng máy tính và hệ thống.",
            "Các Phiến (Blade) riêng lẻ cũng sẽ bao gồm các thiết bị như bộ xử lý, card mạng tích hợp, bộ nhớ trong, các cổng kết nối I/O khác và một bộ chuyển đổi HBA Fiber Channel tùy chọn. Chúng được sử dụng để kết nối các cánh máy chủ này với phần còn lại của máy chủ khác trong hệ thống cũng như giúp kết nối nguồn điện cho các cánh máy chủ độc lập.",
          ],
        },
        {
          title: "Tủ máy chủ",
          detail: [
            "Máy chủ máy tính, phần cứng viễn thông và mạng, thiết bị sản xuất video và thiết bị khoa học đều là những ứng dụng điển hình sử dụng Tủ Máy Chủ.",
            "Khung hoặc thùng chứa tiêu chuẩn hóa phổ biến nhất là 19 inch được sử dụng để gắn nhiều mô-đun thiết bị điện tử. Mỗi thiết bị bao gồm một bảng điều khiển phía trước rộng 19 inch (482.6 mm) bao gồm các cạnh mở rộng từ hai bên của thiết bị, cho phép mô-đun được vặn hoặc bắt vít vào khung giá.",
          ],
        },
      ],
      [
        {
          title: "Hệ thống camera an ninh",
          detail: [
            "CCTV là từ viết tắt của Closed-circuit Television, được hiểu là camera quan sát. Là việc sử dụng các máy quay video để thực hiện nhiệm vụ ghi hình, xử lý các hình ảnh thu được, sau đó truyền tải dữ liệu video đến màn hình theo dõi để người dùng truy cập và quan sát.  Nó khác với truyền hình quảng bá ở chỗ tín hiệu không được truyền công khai, mặc dù nó có thể sử dụng liên kết điểm-điểm (P2P), điểm-đa điểm (P2MP) hoặc liên kết có dây hoặc không dây dạng lưới. Mặc dù hầu hết tất cả các máy quay video đều phù hợp với định nghĩa này, thuật ngữ này thường được áp dụng cho những máy ảnh được sử dụng để giám sát trong các khu vực yêu cầu bảo mật bổ sung hoặc giám sát liên tục.",
          ],
        },
        {
          title: "Camera không dây",
          detail: [
            `Camera Không Dây sử dụng băng tần radio để gửi tín hiệu video và âm thanh đến bộ thu không dây. Nhiều camera an ninh không dây yêu cầu ít nhất một dây cáp hoặc dây dẫn điện để cấp nguồn; thuật ngữ "không dây" liên quan đến cách thức truyền tải video/ âm thanh. Một số Camera Không Dây hoạt động bằng pin, khiến chúng thực sự không dây. Camera Không Dây có ưu điểm là dễ cài đặt hơn camera dùng cáp. Camera sẽ được kết nối liên kết với cơ sở hạ tầng điện của gia đình, do đó việc lắp đặt sẽ yêu cầu dịch vụ của những đơn vị lắp đặt có kinh nghiệm.`,
          ],
        },
        {
          title: "Camera giao thông",
          detail: [
            "Camera Giao Thông giám sát lưu lượng xe trên đường cao tốc, đường quốc lộ, và các tuyến đường đô thị huyết mạch thông qua cáp quang được chôn bên cạnh hoặc bên dưới đường. Hiện nay các hệ thống giao thông thông minh đều được tích hợp Camera Giao Thông, thường là Camera đa tiêu cự có thể kích hoạt các thiết bị an toàn từ xa dựa trên thông tin do camera và các cảm biến khác cung cấp. Trong trường hợp có va chạm giao thông, sự cố gây rối khác hoặc lo ngại về an toàn đường bộ, trung tâm giám sát sẽ theo dõi cảnh quay theo thời gian thực và hoạt động như một người điều phối.",
          ],
        },
        {
          title: "Camera năng lượng mặt trời",
          detail: [
            "Camera Năng Lượng Mặt Trời là thiết bị khép kín không cần dây cáp kết nối nguồn điện để hoạt động. Chúng được cung cấp năng lượng từ việc chuyển hóa quang năng bằng những tấm pin năng lượng mặt trời và cung cấp cho người dùng sự linh hoạt hơn so với camera an ninh có dây. Những thiết bị này phù hợp để sử dụng tại nhà ở, nơi làm việc và các môi trường xa ngoài lưới điện như nông trại và công trường xây dựng. Camera Năng Lượng Mặt Trời có thể sử dụng năng lượng vô thời hạn, trong khi đa phần camera  không dây thông thường luôn ở chế độ tiết kiệm năng lượng và chỉ được kích hoạt khi phát hiện chuyển động. Điều này này gây ra sự chậm trễ, có thể khiến các camera này bỏ lỡ những khoảnh khắc quan trọng.",
          ],
        },
        {
          title: "Bullet camera",
          detail: [
            "Camera Bullet, còn được gọi là Camera Light Laser, là camera an ninh tầm xa ban đêm hỗ trợ chức năng chống rung quang học. Có khả năng giảm hiệu quả tác động của việc rung lắc bằng công nghệ lấy nét tự động và cũng có thể hoạt động hiệu quả trong bóng tối hoàn toàn với các đèn led hồng ngoại và cảm biến ánh sáng, cho hình ảnh rõ nét ở cự ly gần và đảm bảo hình ảnh rõ ràng. Camera có góc chiếu sáng rộng và sử dụng tầm nhìn ban đêm bằng tia hồng ngoại chủ động với độ tương phản nền cao.",
          ],
        },
        {
          title: "Máy ảnh hồng ngoại",
          detail: [
            "Camera Ảnh Nhiệt, hay còn được gọi là Máy Ảnh Hồng Ngoại hoặc Máy Ảnh Tầm Nhiệt (TIC/Thermal Imaging Camera) , là một thiết bị thu và tạo hình ảnh bằng cách sử dụng bức xạ hồng ngoại để “thấy” và “đo” năng lượng bức xạ nhiệt từ một vật thể. Hầu hết các loại Camera Hồng Ngoại chuẩn giống như máy quay phim thông thường, chúng tạo ra một ảnh trực tiếp trên màn hình, nhưng trái ngược với hình ảnh thường thấy trên máy quay thông thường, máy ảnh nhiệt nhạy cảm với ánh sáng hồng ngoại và hình ảnh được tạo ra cho thấy nhiệt độ của vật thể.",
          ],
        },
        {
          title: "Màn hình giám sát",
          detail: [
            "Màn Hình Giám Sát, còn được gọi là Màn Hình An Ninh hoặc Màn Hình CCTV là một thành phần quan trọng trong hệ thống an ninh và hệ thống giám sát. Chúng hoạt động như một màn hình hiển thị cho nhiều loại thiết bị, chẳng hạn như camera an ninh, máy ghi âm và máy tính. Được sử dụng để giám sát các sự kiện bảo mật bằng cách sử dụng nguồn cấp dữ liệu video trực tiếp hoặc các tệp video được lưu trữ, chúng cũng bao gồm các chức năng quản lý sự kiện như lên lịch cảnh báo, phân loại camera theo loại và vị trí, liên kết nhiều màn hình an ninh với nhau để tạo ra một hệ thống duy nhất với các tùy chọn cấu hình như trạm và khung nhìn cửa sổ.",
          ],
        },
      ],
      [
        {
          title: "Hệ thống an ninh lối vào",
          detail: [
            "Hệ Thống An Ninh Lối Vào hay còn được gọi là Hệ Thống Kiểm Soát Ra Vào, được sử dụng để cấp quyền vào các khu vực hạn chế trong môi trường dân cư và thương mại, đảm bảo rằng chỉ những cá nhân được ủy quyền mới được vào. Hệ Thống Kiểm Soát kết hợp thẻ khóa được mã hóa có chèn mã vạch, dải từ hoặc RFID.",
            "Có một số hệ thống cửa ra vào để lựa chọn, bao gồm cửa tự động kích hoạt PIR, bàn phím mã PIN, đầu đọc tiệm cận không tiếp xúc và lối vào cửa sinh trắc học sử dụng nhận dạng khuôn mặt, máy quét vân tay hoặc nhận dạng mống mắt để xác thực nhận dạng.",
          ],
        },
        {
          title: "Máy quét thẻ",
          detail: [
            "Máy Quét Thẻ thường được sử dụng tại các lối vào để đảm bảo an toàn vật lý. Những đầu đọc thẻ có thể quét thông tin xác thực có trên thẻ dưới một hoặc nhiều hình thức để cung cấp hoặc từ chối quyền truy cập vào chủ thẻ.",
            "Đầu đọc thẻ được sử dụng rộng rãi trong các hệ thống kiểm soát truy cập vì chúng cung cấp tính bảo mật cao hơn hệ thống kiểm soát truy cập dựa trên mã PIN. Đầu đọc thẻ hiện đại có thể quét một số loại thẻ khác nhau, bao gồm Thẻ mã vạch, Thẻ từ, Thẻ cảm ứng, Thẻ thông minh,... chuẩn Wiegand.",
          ],
        },
        {
          title: "Máy quét vân tay",
          detail: [
            "Máy Quét Vân Tay là quá trình xác định danh tính của một người dựa trên dấu vân tay và nó rất hữu ích trong việc quản lý danh tính quy mô lớn trên nhiều ứng dụng. Hơn nữa, tiện ích có thể được sử dụng để kiểm soát quyền truy cập vào các hoạt động tài chính hoặc hệ thống giao thông. Trong các ứng dụng này, mục tiêu chính của Máy Quét Vân Tay là xác định hoặc xác thực danh tính của ai đó để ngăn những người chưa được phê duyệt truy cập vào khu vực được bảo vệ. Ngược lại với Hệ thống dựa trên mật khẩu hoặc dựa trên thẻ truy cập, phụ thuộc vào thông tin mật khẩu dễ bị đánh cắp hoặc có thể bị mất.",
          ],
        },
        {
          title: "Máy quét khuôn mặt",
          detail: [
            "Cách đơn giản nhất để phân biệt những người tham gia khác nhau bằng việc có những khuôn mặt riêng. Vì nó xác thực bằng cách nhận dạng khuôn mặt của từng người, cho nên công nghệ Nhận dạng khuôn mặt mang lại cảm giác thoải mái nhất với họ trong khi không bị phản cảm.",
            "Máy Quét Khuôn Mặt sử dụng công nghệ cho phép một người mở khóa lối vào bằng cách chỉ cần nhìn vào thiết bị. Thiết Bị Nhận Dạng Khuôn Mặt có thể được sử dụng trong một tòa nhà để điều chỉnh quyền truy cập tại các khu vực nhất định. Nhận dạng khuôn mặt hỗ trợ xác minh người dùng nhanh chóng, đơn giản và trực quan và cung cấp nhiều tùy chọn khác.",
          ],
        },
        {
          title: "Khóa thông minh",
          detail: [
            "Khóa Thông Minh là ổ khóa cơ điện với công nghệ tương đối mới và đang phát triển. Được thiết kế để thực hiện các hoạt động khóa và mở khóa trên cửa khi nhận được các hướng dẫn như vậy từ một thiết bị được ủy quyền thông qua giao thức không dây và khóa mật mã để thực hiện quá trình ủy quyền. Smart Locks có thể sử dụng tính năng quét vân tay, trong khi các khóa khác thường sử dụng trợ lý kỹ thuật số và ứng dụng dành cho thiết bị di động. Ngoài ra, Smart Locks cung cấp khả năng điều khiển truy cập từ xa.",
          ],
        },
        {
          title: "Khóa từ",
          detail: [
            `Khóa Từ (hay còn gọi tắt là Maglock), là một thiết bị khóa được tạo thành từ một nam châm điện và một tấm cảm ứng. Thiết bị khóa điện được phân thành hai loại. Cơ chế khóa có thể là "fail-safe" nghĩa là khi nguồn điện bị biến mất, khóa từ tính an toàn sẽ tự động khóa hoặc "fail-secure" thì cửa sẽ tự động mở khóa nếu bị cắt điện. Thường thì phần nam châm được gắn vào khung cửa, phần tấm bản kim loại được gắn vào cánh cửa.`,
          ],
        },
        {
          title: "Cửa quay",
          detail: [
            "Cửa Quay hay còn được gọi là Cổng Quay Vòng hoặc Cổng Tự Động ở một số nơi, là loại cửa chỉ cho phép một hành khách đi qua tại một thời điểm. Cửa quay có thể được lập trình để hạn chế lưu lượng người đi theo một hướng và cũng giới hạn lối đi vào đối với những người nhập tiền xu, vé vào cửa hoặc hình thức thanh toán khác. Các cửa sổ quay hiện đại tích hợp sinh trắc học, chẳng hạn như quét vân tay và các đặc điểm cụ thể có thể quét khác của con người. Trong trường hợp nhập cảnh có trả phí, Cửa Quay, thường được biết đến như là cửa soát vé tự động khi được sử dụng cho mục đích này.",
          ],
        },
        {
          title: "Thanh chắn tự động",
          detail: [
            "Thanh Chắn Tự Động, thường được gọi là Boom Gates, thường được bắt gặp ở các bãi đậu xe, trạm kiểm soát và lối vào khu vực hạn chế. Chúng cũng là cách phổ biến nhất để quản lý lối đi qua các trạm thu phí bằng cách chặn lối vào của người đi bộ hoặc phương tiện qua một địa điểm được kiểm soát. Sử dụng một loạt các công nghệ, bao gồm thiết bị đầu vào, điều khiển từ xa, máy dò vòng lặp hoặc bất kỳ thiết bị điều khiển nào của bên thứ ba và cảm biến điện quang. Vì Thanh chắn Tự động nhanh hơn cổng kiểm soát ra vào, chúng được khuyến nghị sử dụng trong thời gian ngắn để quản lý các phương tiện ra vào kho và nhà máy trong giờ làm việc.",
          ],
        },
      ],
    ],
    contact: {
      opening: ["Vui lòng điền vào "," Mẫu ", " dưới đây và nhấn vào nút ", " Xác nhận ", " để gửi thông tin liên hệ cho chúng tôi"],

      button: {
        submit: "Xác nhận"
      },
      legend: [
        "THÔNG TIN CÁ NHÂN",
        "THÔNG TIN DOANH NGHIỆP",
        "SẢN PHẨM & DỊCH VỤ QUAN TÂM",
      ],
      title: {
        name: ["Vui lòng để lại ", "HỌ TÊN", " của bạn"],
        number: ["Và để lại ", "SỐ ĐIỆN THOẠI", " để được liên hệ tư vấn"],
        email: ["Hoặc có thể là ", "EMAIL"],
        companyName: ["Cho chúng tôi biết ", "TÊN CÔNG TY", " của bạn"],
        business: ["Và ", "LĨNH VỰC HOẠT ĐỘNG", " của công ty"],
        address: ["Công ty có trụ sở tại ", "ĐỊA CHỈ", ""],
        products: ["Những ", "SẢN PHẨM", " đang được bạn chú ý"],
        services: ["Những ", "DỊCH VỤ", " nào của chúng tôi có thể phục vụ bạn"],
      },
      options: {
        sex: ["Ông.", "Bà."],
        company: [
          "DN Tư nhân",
          "Cty. TNHH MTV",
          "Cty. TNHH",
          "Cty. Cổ phần",
          "Cty. Hợp danh",
        ],
        business: [
          "Y tế",
          "Bất động sản",
          "Tài chính",
          "Bán lẻ",
          "Sản xuất",
          "Vận tải",
        ],
        product: ["Máy chủ", "Camera an ninh", "Truy cập"],
        service: ["Lắp đặt", "Bảo trì", "Mở rộng", "Nâng cấp"],
      },
      error: {
        name: "Xin vui lòng nhập tên dài hơn",
        phone_number: ["Số điện thoại tại ", " không phải có ", " số"],
        email: "Địa chỉ email không hợp lệ",
        company_address: "Vui lòng nhập địa chỉ chi tiết hơn ",
      },
    },
    guide: { open: "Bấm để xem thêm", close: "Bấm để trở về", copied: "Đã sao chép" },
    footer: {
      company: {
        name: "CÔNG TY HỒNG HOA",
        address:
          "Tổ 7, Khu Phố 4, Phường Trảng Dài, Thành phố Biên Hoà, Tỉnh Đồng Nai, Việt Nam",
      },
      dev: "Trang web này được thiết kế bởi ",
      web:
        "Để được tư vấn chi tiết về thiết kế giao diện website, vui lòng liên hệ qua các phương thức sau",
    },
    loading: "Đang tải..."
  },
  {
    /// english
    menu: [
      { name: "Company profile", path: "/" },
      {
        name: "Products",
        path: "/products",
        children: ["Server", "CCTV", "Access"],
      },

      // { name: "Get in touch", path: "/contact" },
    ],
    home: [
      {
        title: "Profile introduction",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "Hong Hoa single member limited liability company as known is Hong Hoa Tech – a recognized as the leading innovator and enterprise, registered in the provincial city of Dong Nai, with tax identification number ",
            },
            {
              type: "text",
              content: "3601573554",
              url:
                "https://masothue.com/3601573554-cong-ty-tnhh-mot-thanh-vien-hong-hoa",
            },
            {
              type: "text",
              content:
                ". The company was established on March the 19th, 2019 and located in ",
            },
            {
              type: "text",
              content: "Trang Dai Ward, Bien Hoa",
              url:
                "https://www.google.com/maps/place/Khu+Ph%E1%BB%91+4,+Thi%E1%BB%87n+T%C3%A2n,+Th%C3%A0nh+ph%E1%BB%91+Bi%C3%AAn+H%C3%B2a,+%C4%90%E1%BB%93ng+Nai/@11.0019421,106.8760212,15z/data=!3m1!4b1!4m5!3m4!1s0x3174dd061067aef1:0xe58e3bec090770c!8m2!3d11.0019423!4d106.8760212",
            },
            {
              type: "text",
              content:
                " It is considered as one of the promising startups in the Southeast Vietnam, with its own technology and engineering department. The company aims to develop premium innovative and quality technology products that can meet the needs of local communities, customers and partners. As our business continues to grow, we would like to approach with integrity and professionalism.",
            },
          ],
        ],
      },
      {
        title: "Products and Services",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "Our enterprise offers a comprehensive range of services in software development and hardware design. We believe in offering services that can solve your problem. We provide solutions to a variety of problems including computer architectures, sketching, embedded devices, storage networking and data center solutions. Our product includes ",
            },
            {
              type: "internalLink",
              content: "server",
              path: "/products",
              id: 0,
            },
            {
              type: "text",
              content:
                " system that can be developed for business applications as well as enterprise applications, is available in the form of both cloud and on-premises models. The company has support services to clients both small businesses or large corporations depending on the need. We also offer services that deal with embedded devices and storage networking solutions. As well, we offer ",
            },
            {
              type: "internalLink",
              content: "camera",
              path: "/products",
              id: 1,
            },
            { type: "text", content: " and " },
            {
              type: "internalLink",
              content: "access",
              path: "/products",
              id: 2,
            },
            {
              type: "text",
              content:
                " device support that can scale up for any simple client such as desktop or laptop to powerful supercomputer servers.",
            },
          ],
        ],
      },
      {
        title: "Employees and Experiences",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "Hong Hoa Tech is one of the most innovative and up-and-coming technology companies in Viet Nam with a proven track record. To help businesses quickly grow, we offer a wide range of services and products that can be tailored to meet the needs of businesses and consumers. More than that, we offer software support and managed services to comply with the specific needs of their clientele. We have an experienced team of engineers and experts with years of experience in the technology industry who can solve any technical challenges and provide professional and quality technical solutions. The team has worked on various products and projects including large-scale systems for hotels and banks in Vietnam. Our goal is to create a world-class technology company, where our talented team knows how to provide customized solutions that are easy to use for your business needs.",
            },
          ],
        ],
      },
      {
        title: "Area and Price",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "The company’s products and services are designed to be flexible and cost-effective without compromising on quality and effectiveness. With its cutting-edge technology and services, Hong Hoa Tech is poised to become a leading player in the industry sector around the entire Vietnam and Cambodia as well.",
            },
          ],
          [
            {
              type: "text",
              content:
                "Hong Hoa Tech is an innovative and quality technology company that provides solutions to be a variety of problems. The company is known for its excellent customer service and support. We focus on providing quality products to meet the changing needs of the market. Over and above, we are committed to providing customer satisfaction through quality services. Including our products and services in the market will help businesses save money while maintaining quality. We conjointly have a great maintenance service that can keep your technology up and running. Hong Hoa Tech is a company that you can trust to provide the best quality and technology on the market with compatible price on the market.",
            },
          ],
        ],
      },
      {
        title: "Vision and Services' purpose",
        paragraphs: [
          [
            {
              type: "text",
              content:
                "We offer superior quality custom software development and hardware design services. Our goal is to provide you with the highest quality products, while staying within your budget. All of our work is handled in-house with the goal of having you satisfied, satisfied customers are repeat customers!",
            },
          ],
          [
            {
              type: "text",
              content:
                "Technology is changing the way we do things every day, making life easier, more secure and convenient for everyone. At Hong Hoa Tech, we want to be a part of that change. We innovate to provide technology solutions for our customers that will make their lives easier and better. We are committed to providing you with high quality products and support so you can improve your businesses and lead more comfortable lives. We believe that by making these changes now, we can build a better future for all people. Contact us today to learn more and see how we can help you meet your business needs. Thank you for considering us as your technology partner!",
            },
          ],
        ],
      },
    ],
    products: [
      [
        {
          title: "Server room",
          detail: [
            "Server Room or Data Center is full-featured rooms dedicated to the continuous operation of computer servers. Traditionally, they usually contain headless systems, which could be controlled remotely using a KVM switch or remote administration software such as Secure Shell, VNC and remote desktop.",
            "Today Data Centers offer a variety of services for hosting websites, email accounts and other web applications. They also manage application delivery optimization (AOD), internet bandwidth, scalability and high availability for all of these applications.",
          ],
        },
        {
          title: "Ethernet cabble",
          detail: [
            "Ethernet Cable is the wires that link computer to a router or modem. Ethernet connections may appear clumsy or constrained, yet they may significantly enhance the speed and stability of the internet. This commodity is the good-fit solution for home and business connection issues.",
            "An Ethernet Cable, often known as a Network Cable, is a technology that can allow two devices to communicate with each other over an internet connection, granting access to the local area network (LAN) - in other words, internet access.",
          ],
        },
        {
          title: "Fiber optic",
          detail: [
            "Fiber Optics is a technique that transmits information as light pulses across great distances using strands of glass or plastic fiber.",
            "There are several sorts of Fiber-Optic networks, but they all start with an internet connection. Fiber to the Home (FTTH) or Fiber to the Premises (FTTP) is the fastest form of fiber network since it is a 100% Fiber-Optic connection with optical fiber cables placed to terminals directly connected to residences, apartment complexes, and businesses.",
          ],
        },
        {
          title: "Router",
          detail: [
            `Router, usually called Modem is networking devices that routes data packets between computer networks. Understandably, the router conducts "traffic steering" over the Internet. Data packets, such as web pages or emails, are transmitted over the Internet. The data packet will be sent from one router to the next through small networks interconnected to construct a link network until the packet reaches its destination.`,
          ],
        },
        {
          title: "Rack server",
          detail: [
            "Rack Mounted Server, also known as Rack Mountable Server, is ubiquitous in data centers and small businesses with dedicated Server Rooms.",
            "Rack Servers have a greater footprint than conventional computer or server lines. As a result, depending on the server's architecture, it can be attached to the cabinet using specific screws and rails. Rack Server is the most cost-effective alternative for a small number of servers owing to its minimal initial expenditure.",
          ],
        },
        {
          title: "Storage server",
          detail: [
            "Can be used to store, secure, manage, and access files and data. Storage Server allows users to access tiny to big volumes of data over a shared network or the internet. Because it lacks the capacity to provide a large amount of space, storage access interfaces, and dedicated data retrieval and management utilities, a Storage Server is frequently less capable than a regular server.",
            "The Storage Server does not handle computer operations or execute client applications. This server is built on the client-server data storage model, in which clients or workstations will access storage.",
          ],
        },
        {
          title: "GPU server",
          detail: [
            "GPU is an abbreviation for Graphics Processing Unit, which is the graphics-related processor for the CPU central processing unit. Therefore, GPU Server would be able to execute heavy jobs or massive data processing with the aid of GPU.",
            "GPU Server, on average, has higher processing speeds and clearer visuals than other types of servers. GPU Servers might be an excellent alternative for enterprises with huge computational demands or those processing enormous amounts of data. GPU Servers have various benefits over other types of servers.",
          ],
        },
        {
          title: "Blade server",
          detail: [
            "Blade Server is also known as high density server. It is a tiny computer-containing device that is used to manage and distribute data in computer networks and systems.",
            `Every single Blade Server will additionally have a processor, integrated network card, internal memory, additional I/O ports, and an optional HBA Fiber channel switch. They are utilized to connect these server to the rest of the system's server as well as to assist connect electricity to independent server.`,
          ],
        },
        {
          title: "Server cabinet",
          detail: [
            "Computer servers, telecommunications and networking hardware, video production equipment, and scientific equipment are all typical uses Server Cabinet.",
            "The most common standardized frame or container is 19-inch Cabinet  that is utilized to attach numerous electronic equipment modules. Each device comprises a 19-inch (482.6-mm) broad front panel. The 19-inch size includes the edges  that extend from either side of the equipment, allowing the module to be screwed or bolted to the rack frame.",
          ],
        },
      ],
      [
        {
          title: "CCTV system",
          detail: [
            "CCTV System, or Closed-circuit Television System, is the use of video cameras to broadcast a signal to a specified location and display it on a restricted number of monitors. It varies from broadcast television in that the signal is not broadcast publicly, although it may use point-to-point (P2P), point-to-multipoint (P2MP), or mesh wired or wireless lines. Despite the fact that practically all video cameras fulfill this criterion, it is most commonly used to those used for surveillance in locations that require additional security or continual monitoring.",
          ],
        },
        {
          title: "Wireless camera",
          detail: [
            `Wireless Camera uses a radio band to send video and audio signals to a wireless receiver. Many Wireless Security Cameras require at least one cable or wire for power; the term "wireless" relates to video/audio transmission. Some Wireless Cameras, the other side, are battery-powered, making them truly wireless. Wireless Cameras are less difficult to install than Cable Cameras. Many connected cameras link to home's electrical infrastructure, hence installation will require the services of a professional.`,
          ],
        },
        {
          title: "Traffic camera",
          detail: [
            "Traffic Camera monitors vehicle traffic on major highways, freeways, expressways, and arterial roads through optical cables buried beside or beneath the road. Most intelligent transportation systems incorporate Traffic Cameras, which are often Varifocal Cameras that may trigger safety devices remotely based on information provided by the cameras and other sensors. In the case of a traffic collision, other disruptive occurrence, or road safety concern, a monitoring center gets real-time footage and acts as a dispatcher.",
          ],
        },
        {
          title: "Solar powered camera",
          detail: [
            "Solar Powered Camera is self-contained equipment that does not require hard-wiring to operate. Solar Powered Camera are powered by solar panels and provide consumers with greater flexibility than Wired Security Cameras. These devices are ideal for use in residences, workplaces, and distant, off-grid settings like as farms and construction sites. While Solar Powered cameras may record indefinitely, normal Wireless Cameras are typically in power-saving mode to preserve battery power and only wake up when motion is detected. This switch introduces delays, which might cause these cameras to miss important moments.",
          ],
        },
        {
          title: "Bullet camera",
          detail: [
            "Bullet Cameras, also known as Laser Light Cameras, are long-range night vision security cameras that support optical anti-shake function, which can effectively reduce the impact of tower shaking with autofocus technology, and can also work in complete darkness to achieve high-definition night vision at close range and ensure clear imaging. It has a broad illumination angle and uses active laser infrared night vision with high background contrast.",
          ],
        },
        {
          title: "Thermographic camera",
          detail: [
            "A Thermographic Camera, also defined as an Infrared Camera or Thermal Imaging Camera, is a device that captures and generates images using infrared radiation generated by an object in a process known as thermal imaging or thermography. Similar to a Standard Camera that creates a picture using visible light, but as opposed to Visible light Cameras, are sensitive to infrared light, and the image produced shows the temperature of the object.",
          ],
        },
        {
          title: "Surveillance monitor",
          detail: [
            "Surveillance Monitor, also known as Security Monitor or  CCTV Monitor is a key component in security systems and surveillance systems.  They act as the display for a variety of devices, such as cameras, recorders, and computers.  Used to monitor security events using a live video feed or archived video files, they also include event management functionality such as scheduling alerts, sorting cameras by type and locations, linking multiple security monitors together to create a single system with configuration options such as station and window views.",
          ],
        },
      ],
      [
        {
          title: "Entrance system",
          detail: [
            "Enterance System, also known as Access Control System, is used to grant entry to restricted areas in both residential and commercial environments, ensuring that only authorized individuals can enter. Entrance Systems incorporate a coded keycard with a barcode, magnetic stripe, or RFID inserted.",
            "There are several door entry systems to select from, including PIR-triggered automated doors, PIN code keypads, contactless proximity readers, and biometric door entry that uses face recognition, fingerprint scanners, or iris recognition to authenticate identification.",
          ],
        },
        {
          title: "Card reader acess",
          detail: [
            "Card Reader Access is routinely used at entrances to ensure physical security. Such readers scan credentials contained on a card in one or more forms in order to provide or deny access to the cardholder.",
            "Card Readers are widely employed in access control systems because they provide more security than PIN-based access control systems. A modern Card Reader can scan several different types of cards, including Barcodes, Magnetic strips, Wiegand, Proximity cards, and Smart cards.",
          ],
        },
        {
          title: "Finger scanner access",
          detail: [
            "Finger Scanner Access is the process of determining a person's identification based on finger prints and it is useful in large-scale identity management across a wide range of applications. Furthermore, the gadget can be used to control access to financial activities, or transportation systems. In these applications, the primary goal of Finger Scanner is to determine or authenticate somebody's identification in order to prevent unapproved persons from accessing protected area. In contrast to Password-based or Access-card-based Systems, which depend on information or items that can be lost.",
          ],
        },
        {
          title: "Facial recognition access",
          detail: [
            "The most straightforward way to distinguish participants different would be by there own faces. Since it authenticates by identifying people's faces, a Facial Recognition technology feels the closest to them while not being repulsive.",
            "Face Recognition Access makes reference to technology that allows a person to unlock entryways by simply looking at the device. Face Recognition equipment can be used in a building to regulate access to certain areas. Facial Recognition supports in quick, simple, and intuitive user verification and provides a variety of options.",
          ],
        },
        {
          title: "Smart lock",
          detail: [
            "Smart Lock is electro-mechanical locks that are a relatively new and developing technology. Designed to perform locking and unlocking operations on a door when such instructions are received from an authorized device via a wireless protocol and a cryptographic key. Smart Locks can use fingerprint scanning, while others make use of digital assistants and mobile apps. Additionally, Smart Locks provide remote access control.",
          ],
        },
        {
          title: "Magnetic lock",
          detail: [
            `Magnetic Lock, or Maglock for short is a locking device made up of an electromagnet and an armature plate. Electric Locking Devices are classified into two categories. Locking mechanisms might be "fail safe" or "fail secure." When power is disappeared, a Magnetic Lock automatically locks and when de-energized,  it stays unlocked . The magnetic element of the lock is often mounted to the door frame, with a matching armature plate attached to the door.`,
          ],
        },
        {
          title: "Turnstile",
          detail: [
            "Turnstile, also known as a Turnpike or Automated Gate in some regions, is a type of gate that only enables one passenger to pass through at a time. A Turnstile can be programmed to restrict human traffic to one direction and also limit entrance to persons who input a coin, pass, or other form of payment. Modern Turnstiles integrate biometrics, such as fingerprint scanning, and other scannable specific human traits. In the case of paid entry, a Turnstile, often known as a Faregate when used for this purpose.",
          ],
        },
        {
          title: "Automatic barrier",
          detail: [
            "Automatic Barrier, often known as Boom Gate, is commonly encountered in parking facilities, checkpoints, and restricted area entries. They are also the most common way for managing passage through toll booths by blocking pedestrian or vehicular entry through a controlled location. They use a range of technologies, including input devices, remote controls, loop detectors, any third-party control device, and electro - optic sensors. Because Automatic Barriers are speedier than Access Control Gates, they are recommended for short-term usage for managing vehicles entering and departing warehouses and factories during working hours.",
          ],
        },
      ],
    ],
    contact: {
      opening: ["Please fulfill the ", " Form ", " below then click the ", " Submit ", " button to send your information to us."],
      button: {
        submit: "Submit"
      },
      legend: [
        "INDIVIDUAL INFORMATION",
        "COMPANY INFORMATION",
        "INTERESTED SERVICES & PRODUCTS",
      ],
      title: {
        name: ["Let us know ", "YOUR NAME"],
        number: ["Leave your ", "MOBILE NUMBER", " to get contact"],
        email: ["Or maybe an ", "EMAIL"],
        companyName: ["What is the ", "COMPANY'S NAME"],
        business: ["Which ", "BUSINESS FIELD", " does the company work in"],
        address: ["Where is the company ", "LOCATED"],
        products: ["Which ", "PRODUCTS", " are under consideration"],
        services: ["Which are the best-fit ", "SERVICES", " for your case"],
      },
      options: {
        sex: ["Mr.", "Ms."],
        company: [
          "Private Enterprise",
          "Single-member LLC",
          "Co. LTD",
          "Joint Stock Co.",
          "Incorporated Co.",
        ],
        business: [
          "Medical",
          "Real estate",
          "Finance",
          "Retail",
          "Manufacturing",
          "Logistic",
        ],
        product: ["Server", "CCTV", "Access"],
        service: ["Construction", "Maintanence", "Extended", "Upgrade"],
      },
      error: {
        name: "Your name should have few more lettes",
        phone_number: ["", " number phone does not have ", "digit"],
        email: "The email address is invalid",
        company_address: "The address should be longer than ",
      },
    },
    guide: { open: "Click to show more", close: "Click to get back", copied: "Copied" },
    footer: {
      company: {
        name: "HONG HOA COMPANY",
        address:
          "Group 7, Quarter 4, Trang Dai Ward, Bien Hoa City, Dong Nai Province, Vietnam",
      },
      dev: "This web application is powered by ",
      web:
        "If you have any plans to make a web app for your business, feel free to contact via these methods",
    },
    loading: "Loading..."

  },
];
export default Content;
