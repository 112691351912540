import React, { useContext, useEffect, useState } from "react";
import ScrollEndContext from "../../Context/ScrollEnd.Context";
import ScrollingContext from "../../Context/Scrolling.Context";
import LanguageContext from "../../Context/Language.Context";
import HomeContext from "../../Context/Home.Context";
import Content from "../../Content/Content";
import { useNavigate } from "react-router-dom";
import ProductContext from "../../Context/Product.Context";
import "./index.Home.css";
import { isMobile } from "react-device-detect";
import LoadingIndicator from "../Util/LoadingIndicator";
import home from "../../Content/content.Home";

export default function Home() {
  const { isScrolling, setIsScrolling } = useContext(ScrollingContext);
  const { setCurrentProduct } = useContext(ProductContext);
  const { scrollEnd, setScrollEnd } = useContext(ScrollEndContext);
  const { currentLanguage } = useContext(LanguageContext);
  const { currentSection, setCurrentSection } = useContext(HomeContext);
  const [currentScrollTo, setCurrentScrollTo] = useState(1000, () => {});
  const [isTouching, setIsTouching] = useState(false, () => {});
  const [didSwipe, setDidSwipe] = useState(false, () => {});
  const [touchStartAt, setTouchStartAt] = useState(0, () => {});
  const [touchEndAt, setTouchEndAt] = useState(0, () => {});
  const [goDown, setGoDown] = useState(true, () => {});
  const [loading, setLoading] = useState(
    [true, true, true, true, true],
    () => {}
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (currentSection === null) setCurrentSection(0);
    setScrollEnd(false);
  }, []);
  useEffect(() => {
    const viewHeight = document.getElementsByClassName("App")[0].clientHeight;
    const thisContentHeight = document.getElementsByClassName("App-home")[0]
      .scrollHeight;
    const deltaY = (-thisContentHeight + 0.1 * viewHeight + 40) / home.length;
    setCurrentScrollTo(deltaY * currentSection);
    if (currentSection === 0) setGoDown(true);
    if (currentSection === home.length - 1) setGoDown(false);
  }, [currentSection]);
  useEffect(() => {
    if (isTouching) return;
    const deltaY = touchStartAt - touchEndAt;
    if (Math.abs(deltaY) === 0) return;
    if ((didSwipe && deltaY > 0) || (!didSwipe && goDown)) {
      if (currentSection + 1 === home.length) {
        setScrollEnd(true);
        return;
      }
      setCurrentSection(currentSection + 1);
      setGoDown(true);
    } else if ((didSwipe && deltaY < 0) || (!didSwipe && !goDown)) {
      if (currentSection === 0) return;
      if (currentSection + 1 == home.length && scrollEnd) {
        setScrollEnd(false);
        return;
      }
      setCurrentSection(currentSection - 1);
      setGoDown(false);
    } else return;
    setIsScrolling(true);
    setTouchStartAt(0);
    setTouchEndAt(0);
    setDidSwipe(false);
  }, [isTouching]);
  return (
    <div
      className={`App-Screen App-home ${scrollEnd && "Show-footer"}`}
      style={{
        transform: `translateY(${currentScrollTo}px)`,
      }}
      onTransitionEnd={() => {
        setIsScrolling(false);
      }}
      onWheel={(e) => {
        if (Math.abs(e.deltaY) < 50 || isScrolling) return;
        if (e.deltaY > 0) {
          if (currentSection === home.length - 1) {
            setScrollEnd(true);
            return;
          }
          setCurrentSection(currentSection + 1);
        } else {
          if (currentSection === 0) return;
          if (currentSection === home.length - 1 && scrollEnd) {
            setScrollEnd(false);
            return;
          }
          setCurrentSection(currentSection - 1);
        }
        setIsScrolling(true);
      }}
      onTouchMove={(e) => {
        setTouchEndAt(e.targetTouches[0].screenY);
        setDidSwipe(true);
      }}
      onTouchStart={(e) => {
        setIsTouching(true);
        setTouchStartAt(e.targetTouches[0].screenY);
      }}
      onTouchEnd={() => {
        setIsTouching(false);
      }}
    >
      {home.map((src, index) => {
        return (
          <div
            key={index}
            className={`Home-section ${index === currentSection &&
              "Section-Active"}`}
          >
            {loading[index] && index % 2 == 0 ? (
              <LoadingIndicator className={"Home-loading"} />
            ) : (
              ""
            )}

            <img
              key={index}
              className="Home-image"
              onLoad={() => {
                const newLoading = loading;
                newLoading[index] = false;
                setLoading([...newLoading]);
              }}
              src={src}
            />
           
            <div
              className={`Home-text ${scrollEnd &&
                (isMobile ? "Position-top" : "Resolution-text")}`}
            >
              <h1 className="Text-title">
                {currentLanguage === null
                  ? ""
                  : Content[currentLanguage].home[index].title}
              </h1>
              <div className="Text-paragraph-Wrapper">
                {currentLanguage === null
                  ? ""
                  : Content[currentLanguage].home[index].paragraphs.map(
                      (paragraph, pIndex) => (
                        <div key={pIndex} className="Text-paragraph">
                          {paragraph.map((element, eIndex) => {
                            const { type, content, id, path, url } = element;
                            return type === "text" ? (
                              content
                            ) : (
                              <span
                                key={eIndex}
                                title={
                                  type === "internalLink"
                                    ? "Go to"
                                    : "Open link"
                                }
                                className={
                                  type === "internalLink"
                                    ? "Para-in-link"
                                    : type === "externalLink"
                                    ? "Para-ex-link"
                                    : ""
                                }
                                onClick={() => {
                                  if (type === "externalLink") {
                                    window.open(url);
                                  } else if (type === "internalLink") {
                                    setCurrentProduct(id);
                                    navigate(path);
                                  }
                                }}
                              >
                                {content}
                              </span>
                            );
                          })}
                        </div>
                      )
                    )}
              </div>
            </div>
             {loading[index] && index % 2 == 1 ? (
              <LoadingIndicator className={"Home-loading"} />
            ) : (
              ""
            )}
          </div>
        );
      })}
      <div className="App-pre-footer">
        <h2>
          {Content[currentLanguage] &&
            Content[currentLanguage].footer.company.name}
        </h2>
        {!isMobile && (
          <div>
            <h3>
              {Content[currentLanguage] && Content[currentLanguage].footer.dev}{" "}
            </h3>
            <h2>Tailless Scorpion</h2>
          </div>
        )}
      </div>
    </div>
  );
}
