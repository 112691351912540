import React, { useContext } from "react";
import LanguageContext from "../../Context/Language.Context";

export default function LangBtnSection() {
  const langs = ["Tiếng Việt", "English"];
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  return () => {
    return langs.map((lang, id) => (
      <div
        onClick={() => {
          setCurrentLanguage(id);
        }}
        key={id}
        className={`Lang-option ${currentLanguage === id && "Lang-active"}`}
      >
        {lang}
      </div>
    ));
  };
}
