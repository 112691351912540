import React, { useEffect, useState } from "react";
import ImageBox from "./ImageBox";
import Button from "./Button.Product";
import DotSlider from "./DotSlider.Product";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const SlideScroll = ({
  childrenImage,
  childrenContent,
  currentProduct,
  id,
  guide,
}) => {
  const [currentItem, setCurrentItem] = useState(0, () => {});
  const [hasHover, setHasHover] = useState(false, () => {});
  const [hasTimeout, setHasTimeout] = useState(null, () => {});
  const [isOnAuto, setIsOnAuto] = useState(false, () => {});
  const [isGoingNext, setIsGoingNext] = useState(true, () => {});
  const [isOnAnimation, setIsOnAnimation] = useState(false, () => {});
  const [isEndAnimation, setIsEndAnimation] = useState(true, () => {});
  const [itemWidth, setItemWidth] = useState(0, () => {});
  const [centerWidth, setCenterWidth] = useState(0, () => {});
  const [scrollItem, setScrollItem] = useState(0, () => {});
  const [isTouching, setIsTouching] = useState(false, () => {});
  const [didSwipe, setDidSwipe] = useState(false, () => {});
  const [touchStartAt, setTouchStartAt] = useState(0, () => {});
  const [touchEndAt, setTouchEndAt] = useState(0, () => {});
  const [activeProduct, setActiveProduct] = useState(null, () => {});

  const [
    translateScroll,
    setTranslateScroll,
  ] = useState(childrenContent.length * itemWidth + centerWidth, () => {});

  const clearAuto = () => {
    if (hasTimeout) {
      clearTimeout(hasTimeout);
      setHasTimeout(null);
    }
  };
  const previousItem = () => {
    if (!isEndAnimation && hasHover) return;
    clearAuto();
    setIsGoingNext(false);
    setScrollItem(currentItem - 1);
    setCurrentItem(
      currentItem === 0 ? childrenContent.length - 1 : currentItem - 1
    );
  };
  const nextItem = () => {
    if (!isEndAnimation && hasHover) return;
    clearAuto();
    setIsGoingNext(true);
    setScrollItem(currentItem + 1);
    setCurrentItem(
      currentItem === childrenContent.length - 1 ? 0 : currentItem + 1
    );
  };
  const seekItem = (targetItem) => {
    if (!isEndAnimation) return;
    clearAuto();
    const vector = currentItem - targetItem;
    const distance = Math.abs(vector);
    const totalItem = childrenContent.length;
    const carouselDistance = totalItem - distance;
    if (vector > 0) {
      setScrollItem(
        Math.abs(distance) < carouselDistance
          ? targetItem
          : targetItem + totalItem
      );
      setIsGoingNext(Math.abs(distance) < carouselDistance ? false : true);
    } else if (vector < 0) {
      setScrollItem(
        Math.abs(distance) < carouselDistance
          ? targetItem
          : targetItem - totalItem
      );
      setIsGoingNext(Math.abs(distance) < carouselDistance ? true : false);
    }
    setCurrentItem(targetItem);
  };

  const autoFunction = () => {
    if (hasHover || !isEndAnimation) return;
    clearAuto();
    const timeout = setTimeout(
      isGoingNext ? nextItem : previousItem,
      isOnAuto ? 2500 : 5000
    );
    setHasTimeout(timeout);
    if (!isOnAuto) setIsOnAuto(true);
  };

  useEffect(() => {
    setTranslateScroll(
      itemWidth * (childrenContent.length + scrollItem) + centerWidth
    );
    setIsOnAnimation(true);
    setIsEndAnimation(false);
    setTimeout(() => {
      setIsOnAnimation(false);
    }, 500);
  }, [scrollItem]);
  useEffect(() => {
    if (!hasHover && isEndAnimation && currentProduct === id) autoFunction();
    else clearAuto();
  }, [hasHover, isEndAnimation]);
  useEffect(() => {
    if (activeProduct !== null) autoFunction();
    else clearAuto();
  }, [activeProduct]);
  useEffect(() => {
    if (!isOnAnimation) {
      setTranslateScroll(
        itemWidth * (currentItem + childrenContent.length) + centerWidth
      );
      setTimeout(() => {
        setIsEndAnimation(true);
      }, 500);
    }
  }, [isOnAnimation]);
  useEffect(() => {
    if (isTouching || !didSwipe) return;
    const deltaX = touchStartAt - touchEndAt;
    if (Math.abs(deltaX) < 15) return;
    if (deltaX < 0) {
      previousItem();
      setIsOnAuto(false);
    } else if (deltaX > 0) {
      nextItem();
      setIsOnAuto(false);
    }
    setTouchStartAt(0);
    setTouchEndAt(0);
    setDidSwipe(false);
  }, [isTouching, didSwipe]);
  useEffect(() => {
    const screenElement = document.getElementById(`Product-type-Screen-${id}`);
    const containerElement = screenElement.getElementsByClassName(
      "Product-item-Container"
    )[0];
    const itemElementWidth = containerElement.children[0].clientWidth;
    setCenterWidth(-itemElementWidth / 2);
    setItemWidth(-itemElementWidth);
  }, []);
  return (
    <div
      id={`Product-type-Screen-${id}`}
      className={`Product-type-Screen ${currentProduct === id &&
        "Product-type-Active"}`}
      style={{
        backgroundColor: `${
          id <= currentProduct ? "white" : "rgb(30, 100, 255,.1)"
        }`,
      }}
      onTouchMove={(e) => {
        setTouchEndAt(e.targetTouches[0].screenX);
        setDidSwipe(true);
      }}
      onTouchStart={(e) => {
        setIsTouching(true);
        setTouchStartAt(e.targetTouches[0].screenX);
      }}
      onTouchEnd={() => {
        setIsTouching(false);
      }}
    >
      {activeProduct !== null ? (
        <div
          className="Product-Active"
          onClick={() => {
            setActiveProduct(null);
            setIsOnAuto(true);
          }}
        >
          <img
            src={childrenImage[activeProduct].imageUrl}
            style={{
              objectFit: `${childrenImage[activeProduct].type &&
                childrenImage[activeProduct].type}`,
            }}
            alt={childrenImage[activeProduct].name}
            className="Product-Active-image"
          />
          <div className="Product-Active-text">
            <div className="Product-Active-name">
              <h1 style={{ textTransform: "capitalize" }}>
                {childrenContent[activeProduct].title}
              </h1>
            </div>
            <div className="Product-Active-detail">
              <div className="Product-Active-detail-Mask"></div>
              {childrenContent[activeProduct].detail.map((text, index) => (
                <p className="Product-Active-detail-Paragraph" key={index}>
                  {text}
                </p>
              ))}
            </div>
          </div>
          <div className="Product-Active-guide">{guide.close}</div>
        </div>
      ) : (
        <div className="Product-item-Wrapper">
          <Button
            className={`Left-button ${
              !isEndAnimation && !isOnAnimation ? "Disable" : ""
            }`}
            icon={faAnglesLeft}
            handler={() => {
              if (!isEndAnimation) return;
              previousItem();
              setIsOnAuto(false);
            }}
          />
          <DotSlider
            isEndAnimation={isEndAnimation}
            items={childrenContent}
            currentItem={currentItem}
            toggleHandler={(id) => {
              seekItem(id);
              setIsOnAuto(false);
            }}
          />
          <Button
            className={`Right-button ${
              !isEndAnimation && !isOnAnimation ? "Disable" : ""
            }`}
            icon={faAnglesRight}
            handler={() => {
              if (!isEndAnimation) return;
              nextItem();
              setIsOnAuto(false);
            }}
          />
          <div
            className="Product-item-Slide"
            style={{
              transform: `scale(${isOnAnimation ? 0.8 : 1})`,
            }}
          >
            <div
              className={`Product-item-Container
            ${!isEndAnimation && "Product-item-Container-Scroll"}`}
              style={{
                transform: `translateX(${translateScroll}px)`,
                transition: `${
                  isOnAnimation
                    ? "transform .3s ease-in-out .2s, background-color .3s ease-in"
                    : "background-color .4s ease-out"
                }`,
              }}
            >
              {[...childrenContent, ...childrenContent, ...childrenContent].map(
                (product, index) => (
                  <ImageBox
                    guide={guide.open}
                    isEndAnimation={isEndAnimation}
                    clickHandler={() => {
                      if (!isEndAnimation) return;
                      setHasHover(false);
                      setActiveProduct(
                        index < childrenImage.length
                          ? index
                          : index < childrenImage.length * 2
                          ? index - childrenImage.length
                          : index - childrenImage.length * 2
                      );
                    }}
                    hoverHandler={() => {
                      setHasHover(true);
                    }}
                    blurHandler={() => {
                      setHasHover(false);
                    }}
                    currentItem={currentItem}
                    index={index}
                    image={
                      childrenImage[
                        index < childrenImage.length
                          ? index
                          : index < childrenImage.length * 2
                          ? index - childrenImage.length
                          : index - childrenImage.length * 2
                      ]
                    }
                    title={product.title}
                    detail={product.detail}
                    key={index}
                    length={childrenContent.length}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlideScroll;
