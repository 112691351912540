const PUBLIC_PATH = "/Images-videos"
const PATH = {
  SERVER: "/server_",
  CCTV: "/cctv_",
  ACCESS: "/access_"
}
export default {
  server: [
    {
      name: "Server room",
      imageUrl:
        // "https://pente.vn/portals/pente_vn/images/S%E1%BA%A3n%20ph%E1%BA%A9m/datacenter-main-img.jpg",
        PUBLIC_PATH+PATH.SERVER+"01.jpg",
      type: "cover",
    },
    {
      name: "Ethernet cable",
      imageUrl:
      // "http://cdn.shopify.com/s/files/1/0610/7640/6438/products/IKC_1200x1200.png?v=1655951523"
      PUBLIC_PATH+PATH.SERVER+"02.webp",
    },
    {
      name: "Fiber optic",
      imageUrl:
        // "https://assets.website-files.com/5c4b5a2a3b460b3d37c8c9bc/5e3b206812c656e93042e6ae_fiber%20optic%20cabling%20company.png",
        PUBLIC_PATH+PATH.SERVER+"03.png",

    },
    {
      name: "Router",
      imageUrl:
        // "https://cdn.boba.vn/static/san-pham/phu-kien-cong-nghe/thiet-bi-mang/router/wireless-modem.png",
        PUBLIC_PATH+PATH.SERVER+"04.png",
    },
    {
      name: "Rack server",
      imageUrl:
        // "https://serverbazar.in/wp-content/uploads/2021/02/lenovo-thinksystem-sr650-rack-server-intel-xeon-4216-16c.png",
        PUBLIC_PATH+PATH.SERVER+"05.png",
    },
    {
      name: "Storage server",
      imageUrl:
        // "https://static.gigabyte.com/StaticFile/Image/Global/5b12d20f5ca74975cc363a18151f8e2e/Product/19798/png",
        PUBLIC_PATH+PATH.SERVER+"06.png",
    },
    {
      name: "GPU server",
      imageUrl:
        // "https://advcloudfiles.advantech.com/cms/a2a7ed2f-0854-48cb-8343-d91300134167/Content/content-image-1560825511020.png",
        PUBLIC_PATH+PATH.SERVER+"07.png",

    },
    {
      name: "Blade server",
      imageUrl: 
      // "https://happyware.com/media/image/83/6f/2e/Blade-Server.png",
      PUBLIC_PATH+PATH.SERVER+"08.png",
    },
    {
      name: "Server cabinet",
      imageUrl:
        // "https://www.thomas-krenn.com/redx/tools/mb_image.php/ct.VCExSA/cid.y1740140196b2dbbe/Serverraum_ServerschraenkeZubehoer.png",
        PUBLIC_PATH+PATH.SERVER+"09.png",
    },
  ],
  cctv: [
    {
      name: "CCTV system",
      imageUrl:
        // "https://www.virtuenetz.pk/wp-content/uploads/2019/02/security-camera-system-LW162MD-L1.png",
        PUBLIC_PATH+PATH.CCTV+"01.png",
    },
    {
      name: "Wireless camera",
      imageUrl:
        // "https://www.hikvision.com/content/dam/hikvision/products/S000000001/S000000002/S000000003/S000000801/OFR000063/M000038649/images/%E5%8D%8A%E7%90%8354-%E5%9F%BA%E7%BA%BF-%E4%B8%BB%E5%9B%BE.png.thumb.1280.1280.png",
        PUBLIC_PATH+PATH.CCTV+"02.png",
    },
    {
      name: "Traffic camera",
      imageUrl:
        // "https://www.hikvision.com/content/dam/hikvision/products/S000000001/S000000177/S000000212/S000000213/OFR000289/M000019939/images/9-1-iDS-TCM203-A_45du.png.thumb.1280.1280.png",
        PUBLIC_PATH+PATH.CCTV+"03.png",
    },
    {
      name: "Solar powered camera",
      imageUrl:
        // "https://phuongviethcm.com/wp-content/uploads/2021/08/DS-2XS6A87G1-L-C32S80-hinh-1.png",
        PUBLIC_PATH+PATH.CCTV+"04.png",

    },
    {
      name: "Bullet camera",
      imageUrl: 
      // "https://cdn.globalso.com/unviot/%E5%92%8C%E6%99%AERC1.png",
      PUBLIC_PATH+PATH.CCTV+"05.png",
    },
    {
      name: "Thermographic camera",
      imageUrl:
        // "https://tktech.vn/wp-content/uploads/2020/04/HIKVision-DS-2TD2636B-13-p-1.png",
        PUBLIC_PATH+PATH.CCTV+"06.png",
    },
    {
      name: "Surveillance Monitor",
      imageUrl:
        // "https://www.teleview.com.tw/archive/product/item/images/Professional%20Series/TEL-46MR%20Front%201-a.png",
        PUBLIC_PATH+PATH.CCTV+"07.png",
    },
  ],
  access: [
    {
      name: "Entrance system",
      imageUrl:
        // "https://idighardware.com/wp-content/uploads/2020/04/Entrance-Vestibule.jpg",
        PUBLIC_PATH+PATH.ACCESS+"01.webp",
      type: "cover",
    },
    {
      name: "Card reader acess",
      imageUrl:
        // "http://otcaccess.com/wp-content/uploads/2018/09/otcaccess-physical-access-control.png",
        PUBLIC_PATH+PATH.ACCESS+"02.png",
    },
    {
      name: "Finger scanner access",
      imageUrl:
        // "https://www.securetech.com.ng/wp-content/uploads/2022/04/iClock360.png",
        PUBLIC_PATH+PATH.ACCESS+"03.png",
    },

    {
      name: "Facial recognition access",
      imageUrl:
        // "https://www.anviz.com/file/image/3400/1000_1000/facepass7-202003-02.png",
        PUBLIC_PATH+PATH.ACCESS+"04.png",

    },
    {
      name: "Smart lock",
      imageUrl:
      //  "https://imouvn.com/wp-content/uploads/2020/03/K2-imou.png",
      PUBLIC_PATH+PATH.ACCESS+"05.png",

    },
    {
      name: "Magnetic lock",
      imageUrl:
        // "https://www.hikvision.com/content/dam/hikvision/products/S000000001/S000000080/S000000114/S000000115/OFR000147/M000001538/images/DS-K4H250S.png.thumb.1280.1280.png",
        PUBLIC_PATH+PATH.ACCESS+"06.png",

    },
    {
      name: "Turnstile",
      imageUrl:
        // "https://i0.wp.com/www.mysolutions.com.ph/wp-content/uploads/2020/01/TS1000.png",
        PUBLIC_PATH+PATH.ACCESS+"07.png",

    },
    {
      name: "Automatic barrier",
      imageUrl:
        // "https://ultimationdirect.co.uk/wp-content/uploads/2019/12/untitled.150.png",
        PUBLIC_PATH+PATH.ACCESS+"08.png",

    },
  ],
};
