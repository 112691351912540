import React, { useContext, useState } from "react";
import Content from "../../Content/Content";
import LanguageContext from "../../Context/Language.Context";

const CopyBox = ({ content }) => {
  const [isClicked, setIsClicked] = useState(false, () => {});
  const { currentLanguage } = useContext(LanguageContext);
  return (
    <div
      className="Link-Content"
      onClick={() => {
        navigator.clipboard.writeText(content);
        setIsClicked(true);
      }}
      title="COPY"
      onMouseLeave={() => {
        setIsClicked(false);
      }}
    >
      <div>{content}</div>
      {isClicked ? (
        <p>
          {Content[currentLanguage] && Content[currentLanguage].guide.copied}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default CopyBox;
