import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ScrollEndContext from "../../Context/ScrollEnd.Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesDown,
  faAnglesUp,
  faComputerMouse,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import zaloLogo from "../../zalo.svg";
import facebookLogo from "../../facebook.svg";
import lineLogo from "../../line.svg";
import "./index.Footer.css";
import ScrollingContext from "../../Context/Scrolling.Context";
import { useLocation } from "react-router-dom";
import HomeContext from "../../Context/Home.Context";
import ProductContext from "../../Context/Product.Context";
import home from "../../Content/content.Home";
import Content from "../../Content/Content";
import LanguageContext from "../../Context/Language.Context";
import CopyBox from "./CopyBox.Footer";
import LogoBox from "./LogoBox.Footer";
import IconBox from "./IconBox.Footer";

export default function Footer() {
  const { scrollEnd, setScrollEnd } = useContext(ScrollEndContext);
  const { isScrolling } = useContext(ScrollingContext);
  const { currentSection, setCurrentSection } = useContext(HomeContext);
  const { currentProduct, setCurrentProduct } = useContext(ProductContext);
  const { currentLanguage } = useContext(LanguageContext);
  const [footerContent, setFooterContent] = useState(null, () => {});
  const [touchStartAt, setTouchStartAt] = useState(0, () => {});
  const [touchCurrentlyAt, setTouchCurrentlyAt] = useState(0, () => {});
  const [endTouch, setEndTouch] = useState(true, () => {});
  const location = useLocation();
  const clickScroll = () => {
    if (location.pathname === "/") {
      if (currentSection === home.length - 1) {
        if (!scrollEnd) {
          setScrollEnd(true);
        } else {
          setCurrentSection(0);
          setScrollEnd(false);
        }
      } else setCurrentSection(currentSection + 1);
    }
    if (location.pathname === "/products") {
      if (currentProduct === 2) {
        if (!scrollEnd) setScrollEnd(true);
        else {
          setCurrentProduct(0);
          setScrollEnd(false);
        }
      } else setCurrentProduct(currentProduct + 1);
    }
    if (location.pathname === "/contact") {
      setScrollEnd(!scrollEnd);
    }
  };
  useEffect(() => {
    if (currentLanguage !== null)
      setFooterContent(Content[currentLanguage].footer);
  }, [currentLanguage]);
  useEffect(() => {
    if (endTouch) {
      if (touchCurrentlyAt === 0) return;
      const deltaY = touchCurrentlyAt - touchStartAt;
      if (deltaY > 0) {
        setScrollEnd(false);
      }
      setTouchStartAt(0);
      setTouchCurrentlyAt(0);
    }
  }, [endTouch]);

  return (
    <div
      className={`App-footer ${scrollEnd ? "Up_show" : "Down_hide"}`}
      onTouchMove={(e) => {
        setTouchCurrentlyAt(e.targetTouches[0].screenY);
      }}
      onTouchStart={(e) => {
        setEndTouch(false);
        setTouchStartAt(e.targetTouches[0].screenY);
      }}
      onTouchEnd={() => {
        setEndTouch(true);
      }}
      onWheel={(e) => {
        if (Math.abs(e.deltaY) < 50) return;
        if (e.deltaY > 0) {
          setScrollEnd(true);
        } else {
          setScrollEnd(false);
        }
      }}
    >
      <div className="Company-side Footer-side">
        <div className="Company-info">
          <div className="Company-logo-Wrapper">
            <img className="Company-logo" src="/logo.png" />
          </div>
          <div className="Company-text-Wrapper">
            <h2 className="Company-name">
              {footerContent && footerContent.company.name}
            </h2>
            <h3 className="Company-address">
              <div>{footerContent && footerContent.company.address}</div>
            </h3>
          </div>
        </div>
        <div className="Company-button">
          <div className="Company-sign-Wrapper">
            {/* <img className="Company-sign" src="/dathongbao.png" /> */}
          </div>
          <div className="Company-button-Wrapper">
            <div className="Button-Content">
              <div className="Link-Button-Wrapper">
                <IconBox
                  link="tel:+84989081188"
                  title="CALL NOW"
                  icon={faPhone}
                />
                <CopyBox content={"+84989081188"} />
              </div>
              <div className="Link-Button-Wrapper">
                <IconBox
                  icon={faEnvelope}
                  link={`mailto:service@honghoatech.com?cc=service@honghoatech.com&bcc=service@honghoatech.com&subject=${"Contact from website"}`}
                  title="MAIL NOW"
                />
                <CopyBox content={"service@honghoatech.com"} />
              </div>
            </div>
            <div className="Button-Icon">
              <LogoBox
                link="https://zalo.me/0388390688"
                title="ADD ZALO"
                logo={zaloLogo}
              />
              <LogoBox
                link="http://line.naver.jp/ti/p/dEUxsJOQ7U"
                title="ADD LINE"
                logo={lineLogo}
              />
            </div>
          </div>
        </div>
        <div className="Company-pattern"></div>
      </div>
      <div className="Seperate-side"></div>
      <div className="Dev-side Footer-side">
        <h3 className="Dev-declaration">
          {footerContent && footerContent.dev}
          <a href="https://github.com/taillessscorpion" target="_blank">
            Tailless Scorpion
          </a>
        </h3>
        <div className="Dev-info">
          {/*                     PHONE & EMAIL                 */}
          <div className="Dev-info-main">
            <div className="Dev-contact">
              <div>{footerContent && footerContent.web}</div>
            </div>
            <div className="Dev-button">
              <div className="Link-Button-Wrapper">
                <IconBox
                  link="tel:+84829285341"
                  title="CALL NOW"
                  icon={faPhone}
                />
                <CopyBox content={"+84829285341"} />
              </div>
              <div className="Link-Button-Wrapper">
                <IconBox
                  icon={faEnvelope}
                  link={`mailto:taillessscorpion@gmail.com?cc=taillessscorpion@gmail.com.com&bcc=taillessscorpion@gmail.com&subject=${"Contact via HongHoaTech.com"}`}
                  title="MAIL NOW"
                />
                <CopyBox content={"taillessscorpion@gmail.com"} />
              </div>
            </div>
          </div>
          {/*                    ZALO & FACEBOOK                  */}
          <div className="Dev-info-button">
            <LogoBox
              link="https://zalo.me/0829285341"
              title="ADD ZALO"
              logo={zaloLogo}
            />
            <LogoBox
              link="https://www.facebook.com/tailless.scorpion"
              title="ADD FACEBOOK"
              logo={facebookLogo}
              style={{
                backgroundColor: "white",
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={`Footer-wheel ${isScrolling && "Footer-wheel-Hide"}`}
        onClick={clickScroll}
      >
        <FontAwesomeIcon
          icon={faAnglesUp}
          className={`${scrollEnd ? "Wheel-icon_show" : "Wheel-icon_hide"}`}
        />
        {!isMobile && (
          <FontAwesomeIcon icon={faComputerMouse} className={`Wheel-icon`} />
        )}
        <FontAwesomeIcon
          icon={faAnglesDown}
          className={`${scrollEnd ? "Wheel-icon_hide" : "Wheel-icon_show"}`}
        />
      </div>

      <div className="Footer-pattern"></div>
    </div>
  );
}
