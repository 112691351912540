import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import "./index.Products.css";
import ProductContext from "../../Context/Product.Context";
import SlideScroll from "./SlideScroll.Products";
import ScrollingContext from "../../Context/Scrolling.Context";
import ScrollEndContext from "../../Context/ScrollEnd.Context";
import LanguageContext from "../../Context/Language.Context";
import Content from "../../Content/Content";
import products from "../../Content/content.Products";


export default function Products() {
  const { currentLanguage } = useContext(LanguageContext);
  const { currentProduct, setCurrentProduct } = useContext(ProductContext);
  const { isScrolling, setIsScrolling } = useContext(ScrollingContext);
  const { scrollEnd, setScrollEnd } = useContext(ScrollEndContext);
  const [currentScrollTo, setCurrentScrollTo] = useState(1000, () => {});
  const [isTouching, setIsTouching] = useState(false, () => {});
  const [didSwipe, setDidSwipe] = useState(false, () => {});
  const [touchStartAt, setTouchStartAt] = useState(0, () => {});
  const [touchEndAt, setTouchEndAt] = useState(0, () => {});
  const { server, cctv, access } = products;
  const INITIAL_PRODUCT_IMAGE = [server, cctv, access];

  useEffect(() => {
    if (currentProduct === null) setCurrentProduct(0);
    setScrollEnd(false);
  }, []);
  useEffect(() => {
    const thisContentHeight = document.getElementsByClassName(
      "Product-container"
    )[0].scrollHeight;
    const deltaY = -thisContentHeight / 3;
    setCurrentScrollTo(deltaY * currentProduct);
  }, [currentProduct]);
  useEffect(() => {
    if (isTouching || !didSwipe) return;
    const deltaY = touchStartAt - touchEndAt;
    if (deltaY > 0) {
      if (Math.abs(deltaY) < 20) return;
      if (currentProduct + 1 === INITIAL_PRODUCT_IMAGE.length) {
        setScrollEnd(true);
        return;
      }
      setIsScrolling(true);
      setCurrentProduct(currentProduct + 1);
    } else if (deltaY < 0) {
      if (Math.abs(deltaY) < 10) return;
      if (currentProduct === 0) return;
      if (currentProduct + 1 === INITIAL_PRODUCT_IMAGE.length && scrollEnd) {
        setScrollEnd(false);
        return;
      }
      setCurrentProduct(currentProduct - 1);
      setIsScrolling(true);
    }
    setTouchStartAt(0);
    setTouchEndAt(0);
    setDidSwipe(false);
  }, [isTouching, didSwipe]);

  return (
    <div
      className={`App-Screen App-product ${scrollEnd && "Show-footer"}`}
      onTransitionEnd={() => {
        setIsScrolling(false);
      }}
      onWheel={(e) => {
        if (Math.abs(e.deltaY) < 50 || isScrolling) return;
        if (e.deltaY > 0) {
          if (currentProduct + 1 === INITIAL_PRODUCT_IMAGE.length) {
            setScrollEnd(true);
            return;
          }
          setIsScrolling(true);
          setCurrentProduct(currentProduct + 1);
        } else {
          if (currentProduct === 0) return;
          if (
            currentProduct + 1 === INITIAL_PRODUCT_IMAGE.length &&
            scrollEnd
          ) {
            setScrollEnd(false);
            return;
          }
          setCurrentProduct(currentProduct - 1);
          setIsScrolling(true);
        }
      }}
      onTouchMove={(e) => {
        setTouchEndAt(e.targetTouches[0].screenY);
        setDidSwipe(true);
      }}
      onTouchStart={(e) => {
        setIsTouching(true);

        setTouchStartAt(e.targetTouches[0].screenY);
      }}
      onTouchEnd={() => {
        setIsTouching(false);
      }}
    >
      <div
        className="Product-container"
        style={{ transform: `translateY(calc(${currentScrollTo}px))` }}
      >
        {INITIAL_PRODUCT_IMAGE.map((product, index) => (
          <SlideScroll
            id={index}
            currentProduct={currentProduct}
            key={index}
            childrenImage={product}
            childrenContent={
              currentLanguage === null
                ? [
                    {
                      title: "",
                      detail: [""],
                    },
                  ]
                : Content[currentLanguage].products[index]
            }
            active={currentProduct === index}
            guide={
              currentLanguage === null ? "" : Content[currentLanguage].guide
            }
          />
        ))}
      </div>

      <div
        className="App-pre-footer"
        style={{ bottom: "0", position: "fixed" }}
      >
        <h2>
          {Content[currentLanguage] &&
            Content[currentLanguage].footer.company.name}
        </h2>
        {!isMobile && (
          <div>
            <h3>
              {Content[currentLanguage] && Content[currentLanguage].footer.dev}{" "}
            </h3>
            <h2>Tailless Scorpion</h2>
          </div>
        )}
      </div>
    </div>
  );
}
