import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollingContext from "../../Context/Scrolling.Context";

export default function HeaderButton({
  icon,
  className,
  renderChildren,
  useHover,
}) {
  const [hover, setHover] = useState(useHover);
  const { isScrolling } = useContext(ScrollingContext);
  const [hasTimeout, setHasTimeout] = useState(null, () => {});
  const location = useLocation();
  const hoverHandler = () => {
    setHover(true);
    if (hasTimeout) {
      clearTimeout(hasTimeout);
      setHasTimeout(null);
    }
  };
  const fadeHandler = () => {
    setHover(false);
    if (hasTimeout) {
      clearTimeout(hasTimeout);
      setHasTimeout(null);
    }
  };
  const reverseHandler = () => {
    setHover(!hover);
    if (hasTimeout) {
      clearTimeout(hasTimeout);
      setHasTimeout(null);
    }
  };
  useEffect(() => {
    if (isScrolling) {
      setHover(false);
      if (hasTimeout) {
        clearTimeout(hasTimeout);
        setHasTimeout(null);
      }
    }
  }, [isScrolling]);
  useEffect(() => {
    if (hasTimeout) {
      clearTimeout(hasTimeout);
      setHasTimeout(null);
    }
    const timeout = setTimeout(() => {
      setHover(false);
      setHasTimeout(null);
    }, 2500);
    setHasTimeout(timeout);
  }, [location]);
  return (
    <div style={{height: "100%"}}>
      <div
        onMouseEnter={hoverHandler}
        className={`${className} ${hover && "Header-button-active"}`}
        onClick={reverseHandler}
        onMouseLeave={() => {
          if (!useHover || !hasTimeout) fadeHandler();
        }}
      >
        <div className="Button-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        {/* <div className="Button-menu">{renderChildren()}</div> */}

        {hover && (
          <div className="Button-menu">
            <div className="Button-menu-container">{renderChildren()}</div>
            {useHover && (
              <div className="Menu-pattern-container">
                <div className="Menu-pattern"></div>
              </div>
            )}
          </div>
        )}
        {hover && !isBrowser && (
          <div
            className="Button-menu-Outside"
            onClick={() => {
              setHover(false);
            }}
            onMouseEnter={() => {
              setHover(false);
            }}
          ></div>
        )}
      </div>
    </div>
  );
}
